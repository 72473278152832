import React from 'react';
import { graphql } from 'gatsby';
import BasicStructure from '../components/basic-structure/basic-structure';
import './projekt.css';
import ContentContainer from '../components/content-container/content-container';
import Sidebar from '../components/sidebar/sidebar';
import ProjectMenu from '../components/side-bar-components/project-menu';
import { getWpBlock } from '../components/wp-blocks';
import TitleMetaTags from '../components/title-meta-tags/title-meta-tags';
import Footer from '../components/footer/footer';
import './projekt-aktuelles.css';
import Header from '../components/header/header';
import Menubar from '../components/menu/menubar';
import BurgerMenu from '../components/burgermenu/burgermenu';
import ScrollUp from '../components/scroll-up/scroll-up';

/**
 * Simple component rendering the `Aktuell/Speziell, Aktuelles and various Veranstaltung pages`
 */

interface Props {
  data: {
    wpPage: any;
  };
}

function Aktuelles({ data }: Props): JSX.Element {
  const page = data.wpPage;
  const pageTitle = page?.title || '';
  const blocks = page?.blocks || [];

  // console.log('aktuelles', page);
  return (
    <BasicStructure>
      <TitleMetaTags title={pageTitle} />
      <Header>
        <Menubar styleClass="main-menu" />
        <BurgerMenu>
          {page.uri !== '/veranstaltungen/toujours-mozart/' &&
          page.uri !== '/veranstaltungen/musik-auf-raedern/' ? (
            <ProjectMenu id={page.id} parentId={page.parentId} />
          ) : undefined}
        </BurgerMenu>
      </Header>
      {page.uri !== '/veranstaltungen/toujours-mozart/' &&
      page.uri !== '/veranstaltungen/musik-auf-raedern/' ? (
        <Sidebar>
          <ProjectMenu id={page.id} parentId={page.parentId} />
          <ScrollUp />
        </Sidebar>
      ) : (
        <Sidebar>
          <ScrollUp />
        </Sidebar>
      )}
      <ContentContainer>
        <section className="aktuelles-page">
          {(page.uri === '/veranstaltungen/toujours-mozart/' ||
            page.uri === '/veranstaltungen/musik-auf-raedern/') && (
            <h1 className="page-title">{pageTitle}</h1>
          )}
          {blocks.length > 0 &&
            blocks.map((block, index) => {
              const WpBlock = getWpBlock(block.__typename);

              if (!WpBlock) return null;
              const blockKey = `${page.id}-${block.id ?? index}`;

              return (
                <div className="content-block" key={blockKey}>
                  <WpBlock block={block} />
                </div>
              );
            })}
        </section>
      </ContentContainer>
      <Footer />
    </BasicStructure>
  );
}

export const pageQuery = graphql`
  query ($pageId: String!) {
    wpPage(id: { eq: $pageId }) {
      id
      parentId
      title
      uri
      content
      databaseId
      parentDatabaseId
      blocks {
        __typename
        dynamicContent
        isDynamic
        originalContent
        saveContent
        ...WPColumns
        ...WpCoreImageBlockFragment
        ...WpCoreGalleryBlockFragment

        innerBlocks {
          __typename
          saveContent
          isDynamic
          dynamicContent
          ...WpCoreImageBlockForGalleryFragment
          ...WpCoreGalleryBlockFragment
        }
      }
    }
  }
`;

export default Aktuelles;
