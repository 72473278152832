import React from 'react';
import './project-menu.css';
import { graphql, Link, useStaticQuery } from 'gatsby';
import {
  WpPage,
  WpPageConnection,
  WpPageEdge,
} from '../../../generated/graphql';

const query = graphql`
  query {
    allWpPage(filter: { uri: { regex: "/projekte/(.+)/" } }) {
      edges {
        node {
          status
          uri
          title
          slug
          menuOrder
          parentId
          id
        }
      }
    }
  }
`;

function isPublishedProject() {
  return (edge) =>
    edge?.node?.status === 'publish' &&
    edge?.node?.uri?.includes('projekte/') &&
    edge?.node?.slug !== 'projekte';
}

/**
 * Returns true on paths with a number of `/`
 * lower or equal to 3 - like
 * "/projekte/musik-fuer-schueler/
 */
function isNotGrandChildNode() {
  return (edge) => (edge?.node?.uri?.match(/\//gi) || []).length <= 3;
}

function byMenuOrder() {
  return (a, b) =>
    (a?.node?.menuOrder || 0) > (b?.node?.menuOrder || 0) ? 1 : -1;
}

type ListLinkItemProps = {
  title: string;
  href: string;
  listStyleClass?: string;
  linkStyleClass?: string;
  children?: React.ReactNode;
};

function ListLinkItem(props: ListLinkItemProps): JSX.Element {
  const { title, href, listStyleClass, linkStyleClass, children } = props;

  return (
    <li className={listStyleClass}>
      <Link to={href} className={linkStyleClass}>
        <p className="link-list-item__title">{title}</p>
      </Link>
      {children}
    </li>
  );
}

/**
 * Build html elements for all the menue items and their children,
 * the element belonging to the active page will be bolder and a bit large
 * in comparison to the others.
 * @param edge
 * @param index
 * @param currentPageId
 * @param parentPageId
 * @param allChildPageNode
 */
function createLinkListItem(
  edge: WpPageEdge,
  index,
  currentPageId: string,
  parentPageId: string,
  allChildPageNode: WpPage[],
): JSX.Element {
  const isActive =
    currentPageId === edge.node.id || parentPageId === edge.node.id;

  return (
    <ListLinkItem
      key={edge.node.id}
      title={edge.node.title ?? ''}
      href={edge.node.uri ?? '#'}
      listStyleClass="project-menu__item"
      linkStyleClass={`project-menu__link ${
        isActive ? 'active-project-menu' : ''
      } `}
    >
      {isActive && allChildPageNode && allChildPageNode.length > 0 && (
        <ul className="project-submenu">
          {allChildPageNode.map((childPage) => {
            const isActiveChild = currentPageId === childPage.id;
            return (
              <ListLinkItem
                key={childPage.id}
                title={childPage.title ?? ''}
                href={childPage.uri ?? '#'}
                listStyleClass="project-submenu__item"
                linkStyleClass={`project-submenu__link ${
                  isActiveChild ? 'active-project-menu' : ''
                } `}
              />
            );
          })}
        </ul>
      )}
    </ListLinkItem>
  );
}

export interface ProjectMenuProps {
  id: string;
  parentId: string;
}

interface ProjectMenuQuery {
  allWpPage: WpPageConnection;
}

/**
 * Build side menue entries for all `Projekte` pages and their children
 * @param props
 * @constructor
 */
function ProjectMenu(data: ProjectMenuProps): JSX.Element {
  const { id, parentId } = data;
  const projectData = useStaticQuery<ProjectMenuQuery>(query);
  return (
    <div className="project-menu__container">
      <ul className="project-menu">
        {projectData.allWpPage?.edges
          .filter(isPublishedProject())
          .filter(isNotGrandChildNode())
          .sort(byMenuOrder())
          .map((edge, index) => {
            // get all the pages for which this page is the parent page
            const allChildPageNode = projectData.allWpPage.edges
              .filter((otherEdge) => otherEdge.node?.parentId === edge.node?.id)
              .map((edge) => edge.node);
            return createLinkListItem(
              edge,
              index,
              id,
              parentId,
              allChildPageNode,
            );
          })}
      </ul>
    </div>
  );
}

export default ProjectMenu;
